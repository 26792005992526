import React, { useRef, useEffect } from "react";
import useIsInViewport from '../hooks/useIsInViewport.tsx'

export const Features = (props) => {
  const ref = useRef(null);
  const isInViewFront = useIsInViewport(ref);
  useEffect(() => {
    if (isInViewFront && !props.objectInViewFront.feature) props.setObjectInViewFront(e => ({ ...e, 'feature': true }))
    else if (!isInViewFront && props.objectInViewFront.feature) props.setObjectInViewFront(e => ({ ...e, 'feature': false }))
  }, [isInViewFront, props.objectInViewFront.feature])

  return (
    <div id="features" className="text-center" ref={ref}>
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Vision</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.title}-${i}`} className="col-xs-6 col-md-3">
                {" "}
                <i className={d.icon}></i>
                <h3>{d.title}</h3>
                <p>{d.text}</p>
              </div>
            ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
